import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import { tokens } from '../../../theme';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaidIcon from '@mui/icons-material/Paid';
import PublicIcon from '@mui/icons-material/Public';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotesIcon from '@mui/icons-material/Notes';
import HotelIcon from '@mui/icons-material/Hotel';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CakeIcon from '@mui/icons-material/Cake';
import WcIcon from '@mui/icons-material/Wc';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { CheckCircle, Cancel, QrCode, VisibilityOff } from '@mui/icons-material';

const BookingInformation = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');

  const [booking, setBooking] = useState(null);

  const getBookingDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/bookings/${id}`, { headers: { token } });
    const data = await res.json();
    setBooking(data.data);
    console.log(data.data);
  };

  useEffect(() => {
    getBookingDetails();
  }, []);

  // Function to convert backend date to 'YYYY-MM-DD' format
  const convertDate = (backendDate) => {
    const dateObject = new Date(backendDate);
    const day = String(dateObject.getDate()).padStart(2, '0'); // Get day and add leading zero if needed
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and add leading zero if needed
    const year = dateObject.getFullYear(); // Get year
    return `${day}-${month}-${year}`; // Return in 'DD-MM-YYYY' format
  };

  const formatDateForCreatedAt = (date) => {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    return d.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
          <Header title={`Booking #${id}`} subtitle={`Created at ${formatDateForCreatedAt(booking?.createdAt)}`} />

          <Box>
            <Link to={-1}>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
              >
                Back
              </Button>
            </Link>
          </Box>
        </Box>

        {booking && (
          <Box backgroundColor={colors.primary[400]} borderRadius={5} p="1.5rem">
            <Box display="flex" flexDirection="column" gap="1.5rem">
              {/* booking Details */}
              <Box display="flex" flexDirection="column" gap="10px">
                <Typography variant="h3" fontWeight={'bold'} color={colors.grey[100]}>
                  Booking Details
                </Typography>
                <Divider variant="fullWidth" />

                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <PublicIcon fontSize="small" /> Destination:{' '}
                  <span style={{ color: colors.grey[100] }}>{booking?.destination}</span>
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <CalendarTodayIcon fontSize="small" /> Booking Date:{' '}
                  <span style={{ color: colors.grey[100] }}>{booking?.bookingStartDate}</span>
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <BookmarkIcon fontSize="small" /> Contract No:{' '}
                  <span style={{ color: colors.grey[100] }}>{booking?.contractNo}</span>
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <PublicIcon fontSize="small" /> Starting Point:{' '}
                  <span style={{ color: colors.grey[100] }}>{booking?.startDestination}</span>
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <DirectionsBusIcon fontSize="small" /> Bus:{' '}
                  <span style={{ color: colors.grey[100] }}>{booking?.bus}</span>
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <HotelIcon fontSize="small" /> Hotel:{' '}
                  <span style={{ color: colors.grey[100] }}>{booking?.hotel}</span>
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <CorporateFareIcon fontSize="small" /> Partner:{' '}
                  <span style={{ color: colors.grey[100] }}>{booking?.partner}</span>
                </Typography>
              </Box>

              {/* Passenger & Guide Details */}
              <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap="2rem">
                <Stack spacing={1} flex={1}>
                  <Typography variant="h3" fontWeight={'bold'} color={colors.grey[100]}>
                    Passenger Details
                  </Typography>
                  <Divider variant="fullWidth" />

                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PersonIcon fontSize="small" /> Name:{' '}
                    <span style={{ color: colors.grey[100] }}>
                      {booking?.passenger?.name} {booking?.passenger?.surname}
                    </span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <MailOutlineIcon fontSize="small" /> Email:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.passenger?.email}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <LocalPhoneIcon fontSize="small" /> Phone:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.passenger?.phone}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <BadgeIcon fontSize="small" /> Gender:{' '}
                    <span style={{ color: colors.grey[100] }}>
                      {booking?.passenger?.gender === 'm'
                        ? 'Male'
                        : booking?.passenger?.gender === 'f'
                        ? 'Female'
                        : 'Other'}
                    </span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <CakeIcon fontSize="small" /> Date of Birth:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.passenger?.dob}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PlaceIcon fontSize="small" /> Address:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.passenger?.address}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <MenuBookIcon fontSize="small" /> Passport:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.passenger?.passport}</span>
                  </Typography>
                </Stack>

                <Stack spacing={1} flex={1}>
                  <Typography variant="h3" fontWeight={'bold'} color={colors.grey[100]}>
                    Guide Details
                  </Typography>
                  <Divider variant="fullWidth" />

                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PersonIcon fontSize="small" /> Name:{' '}
                    <span style={{ color: colors.grey[100] }}>{`${booking?.guide?.name || ''}`}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <MailOutlineIcon fontSize="small" /> Email:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.guide?.email}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <LocalPhoneIcon fontSize="small" /> Phone:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.guide?.phone}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <BadgeIcon fontSize="small" /> License:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.guide?.license}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <DirectionsBusIcon fontSize="small" /> Vehicle No:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.guide?.vehicleNo}</span>
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PlaceIcon fontSize="small" /> Address:{' '}
                    <span style={{ color: colors.grey[100] }}>{booking?.guide?.address}</span>
                  </Typography>
                </Stack>
              </Box>

              <Stack spacing={1}>
                <Typography variant="h3" fontWeight={'bold'} color={colors.grey[100]}>
                  Excursions
                </Typography>
                <Divider variant="fullWidth" />

                <List>
                  {booking?.excursions?.length === 0 && (
                    <Typography variant="p" component="i">
                      No Excursions found
                    </Typography>
                  )}
                  {booking?.excursions?.map((excursion) => (
                    <Box key={excursion.excursionId}>
                      <ListItem alignItems="center" divider={true}>
                        <ListItemAvatar>
                          <Avatar variant="rounded" alt={excursion?.name} src={excursion?.image} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                              {excursion?.name}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: 'text.primary', display: 'block' }}
                            >
                              {excursion?.location}
                            </Typography>
                          }
                        />
                        <Stack direction="row" spacing={2}>
                          {/* Payment Status */}
                          <Chip
                            icon={excursion?.paymentStatus === 1 ? <CheckCircle /> : <Cancel />}
                            label={excursion?.paymentStatus === 1 ? 'Paid' : 'Unpaid'}
                            color={excursion?.paymentStatus === 1 ? 'success' : 'error'}
                            variant="filled"
                            size="small"
                          />

                          {/* Scan Status */}
                          <Chip
                            icon={excursion?.scanStatus === 1 ? <QrCode /> : <VisibilityOff />}
                            label={excursion?.scanStatus === 1 ? 'Scanned' : 'Not Scanned'}
                            color={excursion?.scanStatus === 1 ? 'warning' : 'default'}
                            variant="filled"
                            size="small"
                          />
                        </Stack>
                      </ListItem>
                    </Box>
                  ))}
                </List>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BookingInformation;

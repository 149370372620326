import { Box, Button, IconButton, Snackbar, useTheme } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FlightIcon from '@mui/icons-material/Flight';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const Excursion = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [excursions, setExcursions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'ExcursionID', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState({
    items: [{ columnField: 'ExcursionName', operatorValue: 'contains', value: '' }],
  });

  const token = localStorage.getItem('token');

  const getData = async () => {
    setLoading(true);

    const sortField = sortModel[0]?.field || 'ExcursionID';
    const sortOrder = sortModel[0]?.sort || 'asc';
    const filterValue = filterModel.items.reduce((acc, item) => {
      acc[item.columnField] = item.value;
      return acc;
    }, {});

    const res = await fetch(
      `${
        process.env.REACT_APP_APILINK
      }/excursion?page=${page}&limit=${pageSize}&sortBy=${sortField}&sortOrder=${sortOrder}&filter=${JSON.stringify(
        filterValue
      )}`,
      { headers: { token } }
    );

    const data = await res.json();
    console.log(data);
    if (data.success) {
      setExcursions(data?.excursions);
      setPage(data?.pagination?.page);
      setPageSize(data?.pagination?.limit);
      setTotalCount(data?.pagination?.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, sortModel, filterModel]);

  // Delete Excursion
  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_APILINK}/excursion/${id}`, {
      headers: { token },
      method: 'DELETE',
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        window.location.reload();
      }
    }
  };

  const columns = [
    { field: 'ExcursionID', headerName: 'ID', hide: true, sortable: false, filterable: false },
    {
      field: 'ExcursionImage',
      headerName: 'Excursion Image',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <img
            src={cellValues.row.ExcursionImage}
            style={{ height: '100%', aspectRatio: '16/9', objectFit: 'cover', padding: '5px', maxWidth: '80px' }}
            alt={cellValues.row.ExcursionName}
          />
        );
      },
    },
    {
      field: 'ExcursionName',
      headerName: 'Name',
      flex: 1,
      minWidth: 120,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'ExcursionDescription',
      headerName: 'Description',
      minWidth: 100,
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'ExcursionLocation',
      headerName: 'Location',
      minWidth: 100,
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'ExcursionPrice',
      headerName: 'Price',
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => `€ ${params.value}`,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'CountryName',
      headerName: 'Destination',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'Edit',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/excursions/${cellValues.row.ExcursionID}`}>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.greenAccent[700] },
              }}
              variant="contained"
            >
              <EditIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Gallery',
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/excursions/${cellValues.row.ExcursionID}/gallery`}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[500],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.blueAccent[600] },
              }}
              variant="contained"
            >
              <VisibilityIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Delete',
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.ExcursionID)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
    setPage(1);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setPage(1);
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
        <Header title="EXCURSIONS" subtitle="All Excursions" />

        <Box>
          <Link to={'/excursions/addexcursion'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <FlightIcon sx={{ mr: '10px' }} />
              Add Excursion
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.grey[100],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={excursions}
          columns={columns}
          getRowId={(row) => row.ExcursionID}
          components={{ Toolbar: CustomToolbar }}
          autoPageSize
          page={page - 1}
          pageSize={pageSize}
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          onPageChange={(newPage) => setPage(newPage + 1)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          paginationMode="server"
          rowCount={totalCount}
          loading={loading}
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default Excursion;

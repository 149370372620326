import { Box, Button, IconButton, Snackbar, useTheme } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { tokens } from '../../../theme';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DownloadIcon from '@mui/icons-material/Download';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const AllBookings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [allBookings, setAllBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const token = localStorage.getItem('token');

  const getData = async () => {
    setLoading(true);

    // Prepare query parameters based on state
    const sortField = sortModel[0]?.field || 'b.id';
    const sortOrder = sortModel[0]?.sort || 'asc';
    const filterValue = filterModel.items.reduce((acc, item) => {
      acc[item.columnField] = item.value;
      return acc;
    }, {});

    const res = await fetch(
      `${
        process.env.REACT_APP_APILINK
      }/admin/bookings?page=${page}&limit=${pageSize}&sortBy=${sortField}&sortOrder=${sortOrder}&filter=${JSON.stringify(
        filterValue
      )}`,
      { headers: { token } }
    );
    const data = await res.json();
    console.log(data);

    if (data.success) {
      setAllBookings(data.data);
      setPage(data?.pagination?.page);
      setPageSize(data?.pagination?.limit);
      setTotalCount(data?.pagination?.total);
    }
    setLoading(false);
  };

  // Function to convert backend date to 'YYYY-MM-DD' format
  const convertDate = (backendDate) => {
    const dateObject = new Date(backendDate);
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, sortModel, filterModel]);

  // Delete Booking
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/bookings/${id}`, {
      method: 'DELETE',
      headers: { token },
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        getData();
      }
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
      filterable: false,
      sortable: false,
    },
    {
      field: 'contractNo',
      headerName: 'Contract No',
      flex: 1,
      minWidth: 70,
      filterable: false,
    },
    {
      field: 'fullName',
      headerName: 'Passenger',
      flex: 1,
      minWidth: 100,
      sortable: false,
      cellClassName: 'name-column--cell',
      valueGetter: (params) => `${params.row.passengerName || ''} ${params.row.passengerSurname || ''}`,
      renderCell: (params) => (
        <div>{params.row.passengerName ? `${params.row.passengerName} ${params.row.passengerSurname}` : '-'}</div>
      ),
    },
    {
      field: 'destination',
      headerName: 'Destination',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'bus',
      headerName: 'Bus',
      flex: 1,
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'guideFullName',
      headerName: 'Guide',
      flex: 1,
      minWidth: 100,
      sortable: false,
      valueGetter: (params) => `${params.row.guideName || ''} ${params.row.guideSurname || ''}`,
      renderCell: (params) => <div>{params.value.trim() ? params.value : '-'}</div>,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => <div>{params.row.paymentStatus ? 'Paid' : 'Not Paid'}</div>,
    },
    {
      field: 'bookingStartDate',
      headerName: 'Booking Date',
      type: 'date',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <div>{params.row.bookingStartDate ? convertDate(params.row.bookingStartDate) : '-'}</div>,
    },
    // {
    //   field: 'bookingEndDate',
    //   headerName: 'Booking End Date',
    //   type: 'date',
    //   flex: 1,
    //   minWidth: 100,
    //   filterable: false,
    //   renderCell: (params) => <div>{params.row.bookingEndDate ? convertDate(params.row.bookingEndDate) : '-'}</div>,
    // },
    {
      field: 'View',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => (
        <Link to={`/booking/${cellValues.row.id}`}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[600],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.blueAccent[700] },
            }}
            variant="contained"
          >
            <VisibilityIcon />
          </Button>
        </Link>
      ),
    },
    {
      field: 'Edit',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => (
        <Link to={`/booking/${cellValues.row.id}/update`}>
          <Button
            sx={{
              backgroundColor: colors.greenAccent[600],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.greenAccent[700] },
            }}
            variant="contained"
          >
            <EditIcon />
          </Button>
        </Link>
      ),
    },
    {
      field: 'Delete',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => (
        <Button
          sx={{
            backgroundColor: colors.redAccent[500],
            color: colors.grey[100],
            ':hover': { backgroundColor: colors.redAccent[600] },
          }}
          variant="contained"
          onClick={() => handleDelete(cellValues.row.id)}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
    setPage(1);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setPage(1);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
        <Header title="BOOKINGS" subtitle="All Bookings" />

        <Box display="flex" gap="10px" flexWrap="wrap">
          <Link to={'/booking/import'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                marginRight: '10px',
                '&:hover': { backgroundColor: colors.blueAccent[800] },
              }}
            >
              <DownloadIcon sx={{ mr: '10px' }} />
              Import Bookings
            </Button>
          </Link>
          <Link to={'/booking/addBooking'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': { backgroundColor: colors.blueAccent[800] },
              }}
            >
              <CalendarTodayIcon sx={{ mr: '10px' }} />
              Add Booking
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': { border: 'none' },
          '& .MuiDataGrid-cell': { borderBottom: 'none' },
          '& .name-column--cell': { color: colors.greenAccent[300] },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': { backgroundColor: colors.primary[400] },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': { color: `${colors.greenAccent[200]} !important` },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={allBookings}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: CustomToolbar }}
          autoPageSize
          page={page - 1}
          pageSize={pageSize}
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          onPageChange={(newPage) => setPage(newPage + 1)} // DataGrid uses 0-based index for pages
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // Handle page size change
          paginationMode="server" // Enable server-side pagination
          rowCount={totalCount} // Total number of rows (data.pagination.total)
          loading={loading}
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AllBookings;
